<script setup lang="ts">
const { isHelpSlideoverOpen } = useDashboard();
const { t } = useI18n();
const { tasksCount } = storeToRefs(useTeamsStore());
const links = computed(() => [
  {
    id: "home",
    label: t("home"),
    icon: "i-heroicons-home",
    to: "/",
    tooltip: {
      text: "Home",
      shortcuts: ["G", "H"],
    },
  },
  {
    id: "map",
    label: t("map"),
    icon: "i-heroicons-home",
    to: "/map",
    tooltip: {
      text: "Map",
      shortcuts: ["G", "M"],
    },
  },
  {
    id: "tasks",
    label: t("tasks"),
    icon: "i-lucide-box",
    to: "/tasks",
    badge: tasksCount.value,
    tooltip: {
      text: t("tasks"),
      shortcuts: ["G", "I"],
    },
  },

  {
    id: "tracking",
    label: t("tracking"),
    icon: "i-mdi-crosshairs-gps",
    to: "/tracking",
    tooltip: {
      text: t("tracking"),
      shortcuts: ["G", "R"],
    },
  },
  {
    id: "settings",
    label: "Settings",
    to: "/settings",
    icon: "i-heroicons-cog-8-tooth",
    children: [
      {
        label: t("general"),
        to: "/settings",
        exact: true,
      },
      {
        label: t("profile"),
        to: "/settings/profile",
      },
      {
        label: t("teams"),
        to: "/settings/teams",
      },
      {
        label: t("teamMembers"),
        to: "/settings/team_members",
      },
      {
        label: t("billing"),
        to: "/settings/billing",
      },
    ],
    tooltip: {
      text: "Settings",
      shortcuts: ["G", "S"],
    },
  },
]);

const footerLinks = [
  {
    label: t("newTask"),
    icon: "i-heroicons-plus",
    click: () => useEmitter().emit("task:create", undefined),
  },
  {
    label: "Help & Support",
    icon: "i-heroicons-question-mark-circle",
    click: () => (isHelpSlideoverOpen.value = true),
  },
];

const groups = [
  {
    key: "links",
    label: "Go to",
    commands: links.value.map((link) => ({
      ...link,
      shortcuts: link.tooltip?.shortcuts,
    })),
  },
];
</script>

<template>
  <UDashboardLayout>
    <UDashboardPanel
      :width="250"
      :resizable="{ min: 200, max: 300 }"
      collapsible
    >
      <UDashboardNavbar class="!border-transparent" :ui="{ left: 'flex-1' }">
        <template #left>
          <TeamsDropdown />
        </template>
      </UDashboardNavbar>

      <UDashboardSidebar>
        <template #header>
          <UDashboardSearchButton />
        </template>

        <UDashboardSidebarLinks :links="links" />

        <UDivider />

        <!-- <UDashboardSidebarLinks
          :links="[{ label: 'Colors', draggable: true, children: colors }]"
          @update:links="(colors) => (defaultColors = colors)"
        /> -->

        <div class="flex-1" />

        <UDashboardSidebarLinks :links="footerLinks" />

        <UDivider class="sticky bottom-0" />

        <template #footer>
          <!-- ~/components/UserDropdown.vue -->
          <UserDropdown />
        </template>
      </UDashboardSidebar>
    </UDashboardPanel>

    <slot />

    <!-- ~/components/HelpSlideover.vue -->
    <HelpSlideover />
    <!-- ~/components/NotificationsSlideover.vue -->
    <NotificationsSlideover />

    <ClientOnly>
      <LazyUDashboardSearch :groups="groups" />
    </ClientOnly>
  </UDashboardLayout>
</template>
