<script setup lang="ts">
const { teams, teamId } = storeToRefs(useTeamsStore());
const { t } = useI18n();
const label = ref("");

const computedTeams = computed(() => {
  label.value = teams.value[0]?.name ?? "";
  return (
    teams.value?.map((t) => {
      return {
        label: capitalizeFirstLetter(t.name),
        click: () => {
          teamId.value = t.id;
          label.value = t.name;
        },
      };
    }) || []
  );
});

const actions = computed(() => [
  {
    label: t("newTeam"),
    icon: "i-heroicons-plus-circle",
    click: () => useEmitter().emit("team:create", undefined),
  },
  {
    label: "Manage teams",
    icon: "i-heroicons-cog-8-tooth",
    to: "/settings/teams",
  },
]);
</script>

<template>
  <UDropdown
    v-slot="{ open }"
    mode="hover"
    :items="[computedTeams, actions]"
    class="w-full"
    :ui="{ width: 'w-full' }"
    :popper="{ strategy: 'absolute' }"
  >
    <UButton
      color="gray"
      variant="ghost"
      :class="[open && 'bg-gray-50 dark:bg-gray-800']"
      class="w-full"
    >
      <UAvatar src="/svgs/logo.svg" size="2xs" />

      <span
        class="truncate text-gray-900 dark:text-white font-semibold capitalize"
        >{{ label }}</span
      >
    </UButton>
  </UDropdown>
</template>
